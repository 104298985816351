<template>
  <div class="container page">
    <van-nav-bar :title="$t('gameRecord.title')" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
    </van-nav-bar>
    <div class="main">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh" :pulling-text="$t('msg.下拉即可刷新')"  :loosing-text="$t('msg.释放即可刷新')" :loading-text="$t('msg.加载中')">
        <van-empty v-if="list.length === 0" :description="$t('msg.没有更多了')" />
        <div v-else class="item_list" v-for="(v,keys) in list" :key="keys">
          <div class="lottery_info">
            <van-image class="cover" :src="v.ico">
              <template v-slot:loading>
                <van-loading type="spinner"/>
              </template>
            </van-image>
            <span class="period-number">{{v.expect}}</span>
            <span class="period-number">{{v.name}}</span>
          </div>
          <div class="recent">
            <div class="kuaisan-ball left">

              <span class="ballBox" v-if="false">{{v.opencode[0]}}</span>
              <span class="ballBox" v-if="false">{{v.opencode[1]}}</span>
              <span class="ballBox" v-if="false">{{v.opencode[2]}}</span>

              <span class="resgardenBox" v-if="false">{{v.status === 0 ? 0 : v.opencode[0] + v.opencode[1] + v.opencode[2]}}</span>
              <span v-if="v.update_time !== '1970-01-01 09:00:00'" class="ressquareBox">{{(v.opencode[0] + v.opencode[1] + v.opencode[2]) >= 11 && (v.opencode[0] + v.opencode[1] + v.opencode[2]) &lt;= 18 ? '스포츠카' : '비행기'}}</span>

              <span v-if="v.update_time !== '1970-01-01 09:00:00'" class="ressquareBox">{{(v.opencode[0] + v.opencode[1] + v.opencode[2]) % 2 === 0 ? '로켓' : '성보'}}</span>

            </div>
          </div>
          <div class="topInfo">
            <span v-if="v.status === 1" style="color: #07c160">{{v.status_text}}</span>
            <span v-else>{{v.status_text}}</span>
            <span>{{ $t("msg.金额") }}：{{v.money}}</span>
            <span>{{ $t("personalreport.profit") }}：{{ v.profit !== undefined ? (v.profit > 0 ? '+' + v.profit : v.profit) : '' }}</span>
          </div>
          <div class="recent">
            <!-- 遍历并显示每个投注的类型 -->
            <div v-if="v.data && v.data.length > 0">
             <span v-for="(bet, index) in v.data" :key="index" class="ressquareBox">
              {{ getBetTypeDisplay(bet.type) }}
             </span>
            </div>
          </div>
          <div class="time">
            <span>{{ $t("gameRecord.create") }}：{{v.create_time}}</span>
          </div>
          <div class="time">
            <span>{{ $t("gameRecord.update") }}：{{v.update_time !== "1970-01-01 09:00:00" ? v.update_time : ""}}</span>
          </div>
        </div>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      list:[],
    };
  },
  methods: {
    back() {
        this.$router.push({ path: '/Mine' });
    },
    onRefresh() {
      setTimeout(() => {
        this.$toast(this.$t('msg.刷新成功！'));
        this.isLoading = false;
      }, 500);
    },
    getUserGameList() {
      this.$http({
        method: 'get',
        url: 'user_get_game_list'
      }).then(res => {
        if (res.code === 200) {
          // 计算每个订单的盈亏
          this.list = res.data.map(order => {
            let totalProfit = 0;
            if (order.data && Array.isArray(order.data)) {
              order.data.forEach(bet => {
                if (bet.is_win === 1) {
                  // 盈利情况下，盈亏为 profit - money
                  totalProfit += parseFloat(bet.profit) - parseFloat(bet.money);
                } else if (bet.is_win === 2) {
                  // 亏损情况下，盈亏为 -money
                  totalProfit -= parseFloat(bet.money);
                }
              });
            // 打印每个订单的投注内容
            console.log(order.data);
            }
            return {
              ...order,
              profit: totalProfit
            };
          });
          console.log(this.list);
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
    // 添加方法来映射投注类型到特定显示文本
    getBetTypeDisplay(type) {
      switch (type) {
       case '大':
        return '스포츠카';  // 大 -> 스포츠카
       case '小':
        return '비행기';   // 小 -> 비행기
       case '单':
        return '성보';     // 单 -> 로켓
       case '双':
        return '로켓';     // 双 -> 성보
       default:
        return type;       // 如果是其他类型，保持原样
      }
    }
      },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserGameList();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.ballBox {
  color: #d75db4;
  font-weight: 700;
  margin-right: 8px;
  font-size: 5vw;
}
.ressquareBox {
  border-radius: 8px;
  padding: 0 8px;
}
.resgardenBox {
  width: 7vw;
  border-radius: 50%;
}
.resgardenBox, .ressquareBox {
  background: linear-gradient(90deg,#f560cd,#4f2b8a);
  color: #fff;
  height: 7vw;
  line-height: 7vw;
  text-align: center;
  font-size: 3vw;
  font-weight: 700;
  margin-right: 15px;
}
::v-deep .van-pull-refresh__track .van-pull-refresh__head *{
  color: #000000;
  font-size: 35px;
}

::v-deep .van-loading__text {
  color: #000000;
  font-size: 35px;
}
.container .main{
  position: relative;
  overflow: auto;
  background-color: #f2f2f5;
  height: 100%;
  padding: 0 10px;
}
.item_list{
  padding: 15px 15px;
  margin: 30px 10px;
  background: #fff;
  border-radius: 10px;
  line-height: 60px;
}

.item_list .topInfo span{
  flex: 1;
  font-size: 35px;
  font-weight: 700;
  color: #ff253f;
}
.item_list .time span{
  flex: 1;
  font-size: 25px;
  font-weight: 500;
  color: #000;
}

.item_list .topInfo span:last-child{
  float: right;
}
.item_list .desc span{
  font-size: 25px;
  font-weight: 700;
  color: #9b9b9b;
}
.item_list .cover{
  width: 60px;
  height: 60px;
  -o-object-fit: cover;
  object-fit: cover;
}
.item_list  .period-number{
  margin-left: 50px;
  margin-right: 10px;
  height: 50px;
  line-height: 60px;
  font-size: 35px;
  font-weight: 700;
  color: #000;
}
.item_list .lottery_info{
  display: flex;
}
.recent {
  display: flex;
  align-items: center;
  height: 100px;
}
.kuaisan-ball .left{
  justify-content: flex-start;
}
.kuaisan-ball{
  flex: 1;
  display: flex;
  align-items: center;
}

.kuaisan-ball .res-img{
  width: 70px;
  height: 70px;
  margin-right: 30px;
}
.kuaisan-ball .res-des{
  font-weight: 700;
  text-align: center;
  color: #000;
}
.kuaisan-ball .res-des.middle{
  width: 15%;
  font-size: 35px;
}
</style>
